import _ from 'underscore'
import Backbone from 'backbone'
import { ajax } from 'util/ajax'
import { renderTemplate } from 'util/template_renderer'

App.MessageType = {
    ERROR: 1,
    NOTICE: 2
};

// renders a form to login / reset pw
App.LoginFormView = Backbone.View.extend({
    events: {
        'click .login_button': 'login',
        'submit .login_form form': 'login',
        'submit .login_button': 'login',
        'click .forgot_password_link': 'forgotPassword',
        'click .use_password_link': 'showPassword'
    },

    initialize: function() {
        var self = this;
        this.options = _.extend({}, this.options, {});
        self.render();
    },

    render: function() {
        var self = this;

        _.bindAll.apply(_, [this].concat(_.functions(this)));
        self.$el.html(renderTemplate("login_form_template", self.options, App.account.get("locale")));

        self.password_field = self.$(".password_field_wrap").detach();
        self.login_code_field = self.$(".login_code_field_wrap").detach();
        return self;
    },

    login: function(evt) {
        evt.preventDefault();
        this.$(".message_area").empty();
        var username = this.$("input[name=username]").val();
        var password = this.$("input[name=password]").val();
        var login_code = this.$("input[name=login_code]").val();
        if (username && password) {
            // password login request
            ajax.request('/account/Login', {
                username: username,
                password: password
            }, [], this.loginSuccess, true, this.loginFailure, this.$(".login_button"));
        } else if (username && login_code) {
            // log in with code
            ajax.request('/account/MobileCodeLogin', {
                email_or_phone: username,
                code: login_code
            }, [], this.loginSuccess, true, this.loginFailure, this.$(".login_button"));
        } else if (username) {
            // link login request
            ajax.request('/account/RequestLoginLink', {
                'email_or_phone': username,
                'login_type': 'desktop'
            }, [], this.quickLinkSuccess, true, this.quickLinkFailure, this.$(".login_button"));
        }
    },

    quickLinkSuccess: function (responseData) {
        if (responseData.is_email) {
            let text = "A login link has been sent to your email address, " + responseData.email_or_phone + "! Open it in order to log in.";
            this.showMessage(text, { persistent: true, messageType: App.MessageType.NOTICE });
        }
        else if (responseData.is_phone) {
            let text = "A login code has been sent to your phone number, " + responseData.email_or_phone + "! Enter it above in order to log in.";
            this.showMessage(text, { persistent: true, messageType: App.MessageType.NOTICE });
            this.showLoginCode();
        }
    },

    quickLinkFailure: function (resp, respData) {
        // Typically we expose the password field, as this user likely has one, unless we are told that this
        // user must authenticate via a 3rd party idp
        var self = this;
        var event;

        if (respData && respData.error === "SSORequiredError" &&
            respData.data && respData.data.idp_url && respData.data.idp_url.length > 0) {
            window.location.href = "/api/saml2/login/?idp=" + respData.data.idp_url;
            return;
        }

        event = new MouseEvent(null);
        self.showPassword(event);
        // return true to suppress error notification (happy path)
        return true;
    },

    loginSuccess: function (resp) {
        App.logClickstream("LOGIN_SUCCESSFUL");
        var self = this;
        setTimeout(function(){
            self.$(".login_button").fadeOut(150);
            App.trigger("app:handle_auth_success");
        },10);
    },

    loginFailure: function(resp, respData) {
        const self = this;
        App.logClickstream("LOGIN_UNSUCCESSFUL");
        var message = "Unable to connect to Twistle";

        if(resp && resp.responseText && resp.responseText.indexOf("InvalidLogin") > -1)
        {
            if(resp.responseText.indexOf("reset your password") > -1)
            {
                message = "Your account is locked. Please use \"Forgot Password?\" to unlock.";
            }
            else
            {
                message = "email / phone / password incorrect.";
            }
        }

        if(respData?.needs_customer_disambiguation){
            // we sent a login code
            self.quickLinkSuccess(respData);
            return true;
        }

        this.showMessage(message);

        return true;
    },

    showMessage: function(message, opts) {
        var self = this;
        opts = opts || {};
        var persistent = opts.persistent || false;
        var messageType = opts.messageType || App.MessageType.ERROR;

        var $elt = self.$(".login_message_area");
        $elt.stop();

        $elt.removeClass('message-area-notice');
        $elt.removeClass('message-area-error');

        if (messageType === App.MessageType.NOTICE) {
            $elt.addClass('message-area-notice');
        }
        else if (messageType === App.MessageType.ERROR) {
            $elt.addClass('message-area-error');
        }

        $elt.css("opacity", 1).show().text(message);

        if (!persistent) {
            $elt.fadeOut(10000);
        }
    },

    showPassword: function(evt) {
        var self = this;
        evt.preventDefault();
        self.$(".login_form_element_wrap").append(self.password_field);
        self.$(".password_field_wrap").show();
        self.$("#password").focus();
        self.$(".use_password_link").hide();
    },

    showLoginCode: function() {
        var self = this;
        self.$(".login_form_element_wrap").append(self.login_code_field);
        self.$(".login_code_field_wrap").show();
        self.$(".use_password_link").hide();
    },

    forgotPassword: function(evt) {
        var self = this;
        evt.preventDefault();
        App.logClickstream("FORGOT_PASSWORD_CLICKED");
        App.trigger("app:forgot_password", {
            username: self.$("input[name=username]").val()
        });
    }

});
