import translations from '../lang/strings_prod.json'
import _ from 'underscore'

let _app = null;

/**
 * Sets the internal reference to the App object. This is used for fetching the user's locale.
 * @param  {Object} app The global App object.
 */
export function setAppObject(app) {
    _app = app;
}


/**
 * Get a localized string for the current user language (also supports loading an alternate language temporarily)
 * @param  {string} tagName The tag or key for the localized string.
 * @param  {string} defaultText The default text to return if the lookup returns nothing.
 * @param  {string?} locale The user's desired locale. Defaults to current user's account's locale or "en" if that is unavailable.
 * @param  {Int} priority The priority of this text for a localizer. 1 means patient-facing, 2 means staff-facing.
 * @param  {string?} comment A comment describing the purpose of the localized string.
 * @returns {string} The localized string.
 */
export function localize(tagName, defaultText, {locale = "", priority = 1, comment = ""} = {}) {
    if (locale == "en") {
        return defaultText;
    }
    else if (locale != "") {
        return localizedValue(tagName, locale, defaultText);
    }
    else {
        let userLocale = _app?.account?.get("locale");
        return localizedValue(tagName, userLocale, defaultText);
    }
}


/**
 * Retrieves the specified key from the specified language. The `zz` language renders emojis for debugging.
 * If the language is any other value, returns null.
 * @param  {string} key The localization string key. Corresponds to keys in `app-{es|pl|zz|etc.}.json`.
 * @param  {string} language The ISO language code for the localization.
 * @param  {string} defaultText The default value to return if the key is not found.
 */
function localizedValue(key, language, defaultText) {
    const localizedValue = translations[key]?.[language];
    return _.isEmpty(localizedValue) ? defaultText : localizedValue;
}


/**
 * A collection of common strings.
 */
export class Strings {
    static get cancel() {
        return localize("common-cancel", "Cancel");
    }

    static get OK() {
        return localize("ok", "OK");
    }
}