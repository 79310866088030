import _ from 'underscore'
import $ from 'jquery'
import Backbone from 'backbone'

import {
    raiseAlert,
} from 'util/twistle'

import { localize } from 'util/localize'
import { ajax } from 'util/ajax'
import { renderTemplate } from 'util/template_renderer'

import {
    validateEmailAddress,
    validatePhoneNumber,
} from 'util/validators'

App.ForgotPasswordView = Backbone.View.extend({
    className:"forgot_password_view",
    events: {
        'click .forgot_type_submit_button': 'forgotTypeSubmit',
        'submit .forgot_step_1 form': 'forgotTypeSubmit',
        'click .method_submit_button': 'requestReset',
        'submit .forgot_step_2_options form': 'requestReset',
        'click .phone_submit_button': 'phoneSubmit',
        'submit .forgot_step_2_phone form': 'phoneSubmit',
        'click .phone_verify_submit_button': 'phoneCodeSubmit',
        'submit .forgot_step_3_phone form': 'phoneCodeSubmit',
        'click .login_return': 'navigateHome',
        'click .signup_email_phone_tab': 'switchSignupTab'
    },
    options:{
        mobileAppMode:false
    },
    initialize: function() {
        var self = this;
        self.step = "1";
        self.phone_masked= "";
        self.render();
    },

    render: function() {
        var self = this;
        self.$(".forgot_password_message_area").hide();
        _.bindAll.apply(_, [this].concat(_.functions(this)));
        self.$el.html(renderTemplate(
            "forgot_password_template",
            self.options,
            App.account && App.account.get("locale")));

        if ( self.phone_masked ) {
            self.$(".phone_masked").html(self.phone_masked );
        }
        else{
            self.$(".reset_phone_option").hide();
        }

        self.$(".pass_step").hide();
        self.$(".forgot_step_" + self.step).show();

        if(self.options.username)
        {
            self.$("input[name=email_check]").val(self.options.username);
        }

        if(self.options.registration_mode)
        {
            // minor UI tweaks
            self.$(".login_form").removeClass("login_quadrant").removeClass("login_bottom_left");
        }

        setTimeout(function(){
            self.postRender();
        }, 20);

        return self;
    },

    postRender: function(){
        var self = this;
        if(self.options.registration_mode)
        {
            self.$("input[name=email_check_phone]").focus();
        }
    },

    forgotTypeSubmit: function(evt) {
        var self = this;
        evt.preventDefault();

        var $emailInput = self.$("input[name=email_check]");


        if(self.options.registration_mode && self.$(".signup_email_phone_tab.selected").hasClass("tab_phone"))
        {
            $emailInput =  self.$("input[name=email_check_phone]");
        }

        var emailVal = $emailInput.val();

        if ( ! emailVal ) {
            var errorMessage = localize("VerificationScreen-validEmail",
                "You must use a valid email address or phone #");
            self.$(".forgot_password_message_area").stop().css("opacity", 1).show().text(errorMessage).fadeOut(40000);
            return;
        }

        self.emailVal = emailVal;

        var isPhone = (!validateEmailAddress(self.emailVal) &&
                        validatePhoneNumber(self.emailVal, App.config.get("country_codes")));

        ajax.request('/account/RequestResetOptions', {
            email: emailVal
            }, [], function(response) {

            if ( response.result === "failed" ) {
                var errorMessage = (response.text || "Unknown error");
                if(self.options.registration_mode)
                {
                    if (isPhone)
                    {
                        errorMessage = localize("RegistrationScreen-phoneDoesNotExist",
                                                            "We don't see an invitation for this phone number. Please review, and you may also want to check with the person who invited you.");
                    }
                    else
                    {
                        errorMessage = localize("RegistrationScreen-emailDoesNotExist",
                                                            "We don't see an invitation for this email address. Please review, and you may want to check with the person who invited you.");
                    }
                }
                else
                {

                    errorMessage = localize("VerificationScreen-validEmail",
                                                            "You must use a valid email address or phone #");

                }
                raiseAlert(errorMessage);
            }
            else if ( response.result === "success" ) {
                if(self.options.registration_mode)
                {
                    self.requestResetSend(isPhone ? "mobile" : "emailCode");
                }
                else
                {
                    self.resetOptions(response.text, isPhone);
                }

            }

        }, true, function(response) {
            var errorMessage = "Reset error, please try again later";
            if(response.responseText.indexOf("PhoneError") > -1) {
                errorMessage = "We don't have a phone number associated with this account. Try the reset by email option instead";
            }
            raiseAlert(errorMessage);
        }, self.$(".forgot_type_submit_button"));

    },

    requestReset: function(evt) {
        var self = this;
        evt.preventDefault();
        var radioVal = self.$("input:radio[name=contact_type]:checked").val();

        if(!radioVal) {
            self.$(".forgot_password_message_area").stop().css("opacity", 1).show().text("Please select your reset method");
        }
        else {
            self.requestResetSend(radioVal);
        }
    },

    requestResetSend: function(method) {
        var self = this;
        App.logClickstream("FORGOT_PASSWORD_TYPE_SUBMIT", {method: method});
        var params = {
            email: self.emailVal,
            method: method
        };
        if(self.options.registration_mode)
        {
            params.for_registration = true;
        }
        ajax.request('/account/RequestReset', params, [], function(response) {
            if(method === "mobile" || method === "mobileCall" || method === "emailCode") {
                self.phoneType = method;
                self.phoneSelect(response);
            }
            else if(method === "email") {
                self.emailSelect(response);
            }
            else {
                self.$(".forgot_password_message_area").stop().css("opacity", 1).show().text("Reset error, please try again").fadeOut(10000);
            }
        }, true, function(response) {
            var errorMessage = "Reset error, please try again later";
            if(response.responseText.indexOf("PhoneError") > -1) {
                errorMessage = "We don't have a phone number associated with this account. Try the reset by email option instead";
            }
            self.$(".forgot_password_message_area").stop().css("opacity", 1).show().text(errorMessage).fadeOut(40000);
        }, self.$(".method_submit_button,.forgot_type_submit_button"));
    },

    resetOptions: function(phoneDigits, isPhone) {
        var self = this;
        self.step = "2_options";
        // as a hack for now, assume text contains last two or three digits of the phone number
        if ( phoneDigits.match( /^[0-9]{2,3}$/ ) ) {
            self.phone_masked = "*******" + phoneDigits;
        }
        self.render();
        self.$(".reset_via_email_option_wrap").toggle(!isPhone);
    },
    emailSelect: function(response) {
        var self = this;
        self.step = "2_email";
        self.render();
    },

    phoneSelect: function(response) {
        var self = this;
        self.step = "3_phone";
        self.render();
        self.$(".forgot_step_3_phone .header_message").text("Check your phone!");
        if(self.phoneType === "mobileCall") {
            self.$(".phone_type_message").stop().css("opacity", 1).show().text(localize("VerificationScreen-verifyByCall-phoneCall",
                "You should receive a phone call with your verification code shortly. Please enter it below."));
        }
        else if(self.phoneType === "mobile") {
            self.$(".phone_type_message").stop().css("opacity", 1).show().text(localize("VerificationScreen-verifyByCall-sms",
                "You should receive a text message (SMS) with your verification code shortly. Please enter it below."));
        }
        else if(self.phoneType === "emailCode") {
            self.$(".forgot_step_3_phone .header_message").text("Check your email!");
            self.$(".phone_type_message").stop().css("opacity", 1).show().text(localize("VerificationScreen-verifyByCall-email",
                "You should receive an email with your verification code shortly. Please enter it below."));
        }
    },

    //This step is somewhat orphaned; it would be used to verify the phone number associated
    //with the email address for reset
    phoneSubmit: function(evt) {
        var self = this;
        evt.preventDefault();

        if(!self.$("input[name=phone]").val()) {
            self.$(".forgot_password_message_area").stop().css("opacity", 1).show().text("Please enter your phone number").fadeOut(10000);
            return;
        }
        self.step = "3_phone";
        self.render();

        if(self.phoneType === "mobileCall") {
            self.$(".phone_type_message").stop().css("opacity", 1).show().text("We're calling you with your password reset code right now. Enter it below once you get it.");
        }
        else if(self.phoneType === "mobile") {
            self.$(".phone_type_message").stop().css("opacity", 1).show().text("We have sent you an SMS with your password reset code. Enter it below once you get it.");
        }
    },

    phoneCodeSubmit: function(evt) {
        var self = this;
        evt.preventDefault();

        var resetCode = self.$("input[name=code]").val();

        ajax.request('/account/CheckMobileCode', {
            email: self.emailVal,
            code: resetCode
        }, [], function(response) {
            if(response.result === "success" && response.e && response.t) {
                App.logClickstream("FORGOT_PASSWORD_SUCCESS");
                self.$(".forgot_password_message_area").stop().css("opacity", 1).show().text("Processing...");

                const isProvisional = response.provisional;

                var params = {
                    e:response.e,
                    t:response.t,
                    return_json:1
                };

                ajax.request("/account/ResetPassword",params,[],function(resp){
                    var changeToken = resp.text;

                    if(isProvisional)
                    {
                        App.logClickstream("FORGOT_PASSWORD_TO_REGISTRATION");
                        setTimeout(function() // need to defer to allow a session token to be established.
                        {
                            ajax.request("/account/ProvisionalLoginByPasswordReset", {
                                e: params.e,
                                t: changeToken,
                                return_json: 1
                            }, [], function (resp)
                            {
                                App.config.set("isProvisional", true);
                                App.config.set("provisionalUsername", resp.username);

                                App.trigger("app:start_account_setup", {from_verification_reg:self.options.registration_mode});
                            });
                        }, 0);
                    }
                    else
                    {
                         if(response.needs_customer_disambiguation){
                            // bounce out to disambiguation mode
                            window.location.href = '/account/disam';
                            return;
                        }
                        App.config.set('reset_e', params.e);
                        App.config.set('reset_t', changeToken);
                        App.trigger("app:password_reset");
                    }

                },false,function(){
                    self.$(".forgot_password_message_area").stop().css("opacity", 1).show().text("An error occurred, please try again later");
                });
            }
            else {
                self.$(".forgot_password_message_area").stop().css("opacity", 1).show().text("An error occurred, please try again later");
            }

        }, true, function(response) {
            var errorMessage = "An error occured. Please try again later.";
            if(response.responseText.indexOf("CodeError") > -1) {
                errorMessage = "Invalid code. Please try again.";
            }
            self.$(".forgot_password_message_area").stop().css("opacity", 1).show().text(errorMessage).fadeOut(10000);
        }, self.$(".phone_verify_submit_button"));

    },

    navigateHome: function(evt) {
        evt.preventDefault();
        App.trigger("app:reset_login_desktop");
    },

    switchSignupTab: function(evt)
    {
        var self = this, $tab = $(evt.currentTarget);

        self.$(".signup_email_phone_tab.selected").removeClass("selected");
        $tab.addClass("selected");

        self.$(".tab_phone_body").toggle($tab.hasClass("tab_phone"))
        self.$(".tab_email_body").toggle($tab.hasClass("tab_email"))

    }
});