import $ from 'jquery'
import _ from 'underscore'
import { Spinner } from 'spin.js'
import 'spin.js/spin.css'

// jquery spin plugin (requires Spin.js)
$.fn.spin = function(opts)
{
    var self = this;

    if(opts && opts.stop)
    {
        if(self.data("spinner_widget"))
        {
            self.data("spinner_widget").stop();
        }
        return;
    }

    // clear others
    self.find(".spinner").remove()

    opts = _.defaults(opts || {}, {
            lines: 8, // The number of lines to draw
            length: 6, // The length of each line
            width: 2, // The line thickness
            radius: 6, // The radius of the inner circle
            corners: 0.3, // Corner roundness (0..1)
            rotate: 0, // The rotation offset
            direction: 1, // 1: clockwise, -1: counterclockwise
            color: '#000', // #rgb or #rrggbb or array of colors
            speed: 1.4, // Rounds per second
            trail: 48, // Afterglow percentage
            shadow: false, // Whether to render a shadow
            hwaccel: true, // Whether to use hardware acceleration
            className: 'spinner', // The CSS class to assign to the spinner
            zIndex: 2e9, // The z-index (defaults to 2000000000)
            top: '50%', // Top position relative to parent in px
            left: '50%' // Left position relative to parent in px
        });

    var spinner = new Spinner(opts).spin(self.get(0));

    self.data("spinner_widget", spinner);

    return self;
};