//////////////////////////////////////////////////////////////////////////////////////
//
//	Copyright 2012 Piotr Walczyszyn (http://outof.me | @pwalczyszyn)
//
//	Licensed under the Apache License, Version 2.0 (the "License");
//	you may not use this file except in compliance with the License.
//	You may obtain a copy of the License at
//
//		http://www.apache.org/licenses/LICENSE-2.0
//
//	Unless required by applicable law or agreed to in writing, software
//	distributed under the License is distributed on an "AS IS" BASIS,
//	WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//	See the License for the specific language governing permissions and
//	limitations under the License.
//
//////////////////////////////////////////////////////////////////////////////////////

import Backbone from "backbone";
import _ from "underscore";
import $ from "jquery";


var Effect = function Effect(params) {
    if (params) {
        _.extend(this, params);
    }
    this.vendorPrefix = "";
    this.transitionEndEvent = "transitionend";
};

// Shared empty constructor function to aid in prototype-chain creation.
var ctor = function () {};

Effect.extend = function (protoProps, staticProps) {
    var child = function () {
        Effect.apply(this, arguments);
    };

    // Inherit class (static) properties from parent.
    _.extend(child, Effect);

    // Set the prototype chain to inherit from `parent`, without calling
    // `parent`'s constructor function.
    ctor.prototype = Effect.prototype;
    child.prototype = new ctor();

    // Add prototype properties (instance properties) to the subclass,
    // if supplied.
    if (protoProps) _.extend(child.prototype, protoProps);

    // Add static properties to the constructor function, if supplied.
    if (staticProps) _.extend(child, staticProps);

    // Correctly set child's `prototype.constructor`.
    child.prototype.constructor = child;

    // Set a convenience property in case the parent's prototype is needed later.
    child.__super__ = Effect.prototype;

    return child;
};


var SlideEffect = Effect.extend({
    direction: "left",

    fromViewTransitionProps: { duration: 0.4, easing: "ease-out", delay: 0 },

    toViewTransitionProps: { duration: 0.4, easing: "ease-out", delay: 0 },

    play: function ($fromView, $toView, callback, context) {
        var timeout,
            that = this,
            activeTransitions = 0,
            transformParams,
            transformProp = that.vendorPrefix == "" ? "transform" : ["-" + that.vendorPrefix, "-", "transform"].join(""),
            transitionProp = that.vendorPrefix == "" ? "transition" : ["-" + that.vendorPrefix, "-", "transition"].join("");

        var transitionEndHandler = function (event) {
            if (activeTransitions >= 0) {
                activeTransitions--;

                var $target = $(event.target);
                $target.css(transformProp, "");
                $target.css(transitionProp, "");

                if ($toView && $toView[0] == event.target) $toView.css("left", 0);

                if (activeTransitions == 0 && callback) {
                    if (timeout) clearTimeout(timeout);
                    callback.call(context);
                }
            }
        };

        if ($fromView) {
            activeTransitions++;

            $fromView.one(that.transitionEndEvent, transitionEndHandler);

            $fromView.css("left", 0);
            $fromView.css(
                transitionProp,
                [
                    transformProp,
                    " ",
                    that.fromViewTransitionProps.duration,
                    "s ",
                    that.fromViewTransitionProps.easing,
                    " ",
                    that.fromViewTransitionProps.delay,
                    "s",
                ].join("")
            );
        }

        if ($toView) {
            activeTransitions++;

            $toView.one(that.transitionEndEvent, transitionEndHandler);

            $toView.css("left", that.direction == "left" ? context.$el.width() : -context.$el.width());
            $toView.css(
                transitionProp,
                [
                    transformProp,
                    " ",
                    that.toViewTransitionProps.duration,
                    "s ",
                    that.toViewTransitionProps.easing,
                    " ",
                    that.toViewTransitionProps.delay,
                    "s",
                ].join("")
            );

            // Showing the view
            $toView.css("visibility", "visible");
        }

        if ($fromView || $toView) {
            // This is a hack to force DOM reflow before transition starts
            context.$el.css("width");

            transformParams = "translate3d(" + (that.direction == "left" ? -context.$el.width() : context.$el.width()) + "px, 0, 0)";
        }

        // This is a fallback for situations when TransitionEnd event doesn't get triggered
        var transDuration =
            Math.max(that.fromViewTransitionProps.duration, that.toViewTransitionProps.duration) +
            Math.max(that.fromViewTransitionProps.delay, that.toViewTransitionProps.delay);

        timeout = setTimeout(function () {
            if (activeTransitions > 0) {
                activeTransitions = -1;

                console.log("Warning " + that.transitionEndEvent + " didn't trigger in expected time!");

                if ($toView) {
                    $toView.off(that.transitionEndEvent, transitionEndHandler);
                    $toView.css(transitionProp, "");
                    $toView.css(transformProp, "");
                    $toView.css("left", 0);
                }

                if ($fromView) {
                    $fromView.off(that.transitionEndEvent, transitionEndHandler);
                    $fromView.css(transitionProp, "");
                    $fromView.css(transformProp, "");
                }

                callback.call(context);
            }
        }, transDuration * 1.5 * 1000);

        var $views;
        if ($fromView && $toView) $views = $fromView.add($toView);
        else if ($toView) $views = $toView;
        else if ($fromView) $views = $fromView;

        if ($views) $views.css(transformProp, transformParams);
    },
});


let FadeEffect = Effect.extend({
    fromViewTransitionProps: { duration: 0.4, easing: "linear", delay: 0.1 },

    toViewTransitionProps: { duration: 0.4, easing: "linear", delay: 0.1 },

    play: function ($fromView, $toView, callback, context) {
        var that = this,
            timeout,
            activeTransitions = 0,
            transitionProp = that.vendorPrefix == "" ? "transition" : ["-" + that.vendorPrefix.toLowerCase(), "-", "transition"].join("");

        var transitionEndHandler = function (event) {
            if (activeTransitions >= 0) {
                activeTransitions--;

                $(event.target).css(transitionProp, "");

                if (activeTransitions == 0 && callback) {
                    if (timeout) clearTimeout(timeout);
                    callback.call(context);
                }
            }
        };

        if ($fromView) {
            activeTransitions++;

            // Registering transition end handler
            $fromView.one(that.transitionEndEvent, transitionEndHandler);

            // Setting transition css props
            $fromView.css(
                transitionProp,
                [
                    "opacity ",
                    that.fromViewTransitionProps.duration,
                    "s ",
                    that.fromViewTransitionProps.easing,
                    " ",
                    that.fromViewTransitionProps.delay,
                    "s",
                ].join("")
            );
        }

        if ($toView) {
            activeTransitions++;

            $toView.one(that.transitionEndEvent, transitionEndHandler);

            // Setting initial opacity
            $toView.css("opacity", 0);

            // Setting transition css props
            $toView.css(
                transitionProp,
                [
                    "opacity ",
                    that.toViewTransitionProps.duration,
                    "s ",
                    that.toViewTransitionProps.easing,
                    " ",
                    that.toViewTransitionProps.delay,
                    "s",
                ].join("")
            );

            // Showing the view
            $toView.css("visibility", "visible");
        }

        // This is a hack to force DOM reflow before transition starts
        context.$el.css("width");

        // This is a fallback for situations when TransitionEnd event doesn't get triggered
        var transDuration =
            Math.max(that.fromViewTransitionProps.duration, that.toViewTransitionProps.duration) +
            Math.max(that.fromViewTransitionProps.delay, that.toViewTransitionProps.delay);

        timeout = setTimeout(function () {
            if (activeTransitions > 0) {
                activeTransitions = -1;

                console.log("Warning " + that.transitionEndEvent + " didn't trigger in expected time!");

                if ($toView) {
                    $toView.off(that.transitionEndEvent, transitionEndHandler);
                    $toView.css(transitionProp, "");
                }

                if ($fromView) {
                    $fromView.off(that.transitionEndEvent, transitionEndHandler);
                    $fromView.css(transitionProp, "");
                }

                callback.call(context);
            }
        }, transDuration * 1.5 * 1000);

        if ($toView) $toView.css("opacity", 1);
        if ($fromView) $fromView.css("opacity", 0);
    },
});


let NoEffect = Effect.extend();
NoEffect.prototype.play = function ($fromView, $toView, callback, context) {
    if ($toView) {
        // Showing the view
        $toView.css("visibility", "visible");
    }
    callback.call(context);
};


/**
         * Rendering the view and setting props required by StackNavigator.
         * @private
         * @ignore
         *
         * @param {View} view View to be rendered.
         * @param {StackNavigator} stackNavigator View StackNavigator instance.
         */
function appendView(view, stackNavigator) {
    if (!view.__backStackRendered__) {
        // Setting ref to parent StackNavigator
        view.stackNavigator = stackNavigator;

        // Setting default destructionPolicy if it's not set
        if (typeof view.destructionPolicy === "undefined") view.destructionPolicy = "auto";

        // Setting default styles
        view.$el.css({ position: "absolute", visibility: "hidden", overflow: "hidden", width: "100%", height: "100%" });
    } else {
        // Resetting visibility to hidden
        view.$el.css({ visibility: "hidden" });
    }

    // Adding view to the DOM
    if (view.destructionPolicy !== "never" || !view.$el.parent().length) {
        stackNavigator.$el.append(view.el);
    } else {
        view.$el.show();
    }

    if (!view.__backStackRendered__) {
        // Rendering the view
        view.render.call(view);

        // Setting default of __backStackRendered__ property
        view.__backStackRendered__ = true;
    }
}

/**
 * Creates event objects triggered by BackStack.
 * @private
 * @ignore
 *
 * @param {string} type Event type name.
 * @param {*} args Event args.
 * @param {boolean} cancelable Flag indicating if event is cancelable.
 * @return {event} The new object.
 */
function createEvent(type, args, cancelable) {
    return _.extend(
        {
            type: type,

            cancelable: _.isUndefined(cancelable) ? false : cancelable,

            preventDefault: function () {
                if (this.cancelable)
                    this.isDefaultPrevented = function () {
                        return true;
                    };
            },

            isDefaultPrevented: function () {
                return false;
            },

            trigger: function (target) {
                target.trigger(this.type, this);
                return this;
            },
        },
        args
    );
}

/**
 * Private common push method.
 * @private
 * @ignore
 *
 * @param {object} fromViewRef Reference to from view.
 * @param {object} toViewRef Reference to to view.
 * @param {number} replaceHowMany Number of views to replace with pushed view.
 * @param {Effect} transition Transition to played during push.
 */
function push(fromViewRef, toViewRef, replaceHowMany, transition) {
    // Rendering view if required
    appendView(toViewRef.instance, this);

    transition = new NoEffect(); //transition || this.defaultPushTransition || (this.defaultPushTransition = new SlideEffect({direction:'left'}));
    transition.play(
        fromViewRef ? fromViewRef.instance.$el : null,
        toViewRef.instance.$el,
        function () {
            // Callback function

            var remove =
                replaceHowMany > 0
                    ? this.viewsStack.splice(this.viewsStack.length - replaceHowMany, replaceHowMany)
                    : fromViewRef
                    ? [fromViewRef]
                    : null;

            if (remove != null) {
                _.each(
                    remove,
                    function (ref) {
                        // Triggering viewDeactivate event
                        createEvent("viewDeactivate", { target: ref.instance }).trigger(ref.instance);

                        if (ref.instance.destructionPolicy == "never") {
                            // Detaching if destructionPolicy == 'never'
                            ref.instance.$el.hide();
                        } else {
                            // Removing if destructionPolicy == 'auto'
                            ref.instance.remove();
                            ref.instance = null;
                        }
                    },
                    this
                );
            }

            // Adding view to the stack internal array
            this.viewsStack.push(toViewRef);

            // Setting activeView property
            this.activeView = toViewRef.instance;

            // Triggering viewActivate event
            createEvent("viewActivate", { target: toViewRef.instance }).trigger(toViewRef.instance);

            // Triggering viewChanged event
            createEvent("viewChanged", { target: this }).trigger(this);

            // Popping item from actions queue
            popActionsQueue.call(this);
        },
        this
    );
}

/**
 * Private common pop method.
 * @private
 * @ignore
 *
 * @param {object} fromViewRef Reference to from view.
 * @param {object} toViewRef Reference to to view.
 * @param {number} howMany Number of views to pop from the stack.
 * @param {Effect} transition Transition to played during pop.
 */
function pop(fromViewRef, toViewRef, howMany, transition) {
    if (toViewRef) {
        // Recreating view instance if necessary
        toViewRef.instance = toViewRef.instance ? toViewRef.instance : new toViewRef.viewClass(toViewRef.options);
        // Rendering view if required
        appendView(toViewRef.instance, this);
    }
    var self = this;
    setTimeout(function () {
        transition = new NoEffect(); //transition || self.defaultPopTransition || (self.defaultPopTransition = new SlideEffect({direction:'right'}));
        transition.play(
            fromViewRef.instance.$el,
            toViewRef ? toViewRef.instance.$el : null,
            function () {
                // Callback function

                // Popping views from a stack
                var remove = self.viewsStack.splice(self.viewsStack.length - howMany, howMany);
                _.each(
                    remove,
                    function (ref) {
                        // Triggering viewDeactivate event
                        createEvent("viewDeactivate", { target: ref.instance }).trigger(ref.instance);

                        //if (ref.instance.destructionPolicy == 'never') { // Detaching if destructionPolicy == 'never'
                        //    ref.instance.$el.hide();
                        //} else { // Removing if destructionPolicy == 'auto'
                        ref.instance.close();
                        ref.instance = null;
                        //}
                    },
                    self
                );

                if (toViewRef) {
                    // If toViewRef exists activating it

                    // Setting activeView property
                    self.activeView = toViewRef.instance;

                    // Triggering viewActivate event
                    createEvent("viewActivate", { target: toViewRef.instance }).trigger(toViewRef.instance);
                } else {
                    // Nulling activeView property
                    self.activeView = null;
                }

                // Triggering viewChanged event
                createEvent("viewChanged", { target: self }).trigger(self);

                // Popping item from actions queue
                popActionsQueue.call(self);
            },
            self
        );
    }, 85);
}

function pushView(view, viewOptions, transition) {
    // Getting ref of the view on top of the stack
    var fromViewRef = _.last(this.viewsStack),
        // Creating new view instance if it is necessary
        toView = _.isFunction(view) ? new view(viewOptions) : view,
        // Creating new view ref
        toViewRef = { instance: toView, viewClass: toView.constructor, options: viewOptions },
        // Creating viewChanging event object and triggering it
        event = createEvent(
            "viewChanging",
            {
                action: "push",
                fromViewClass: fromViewRef ? fromViewRef.viewClass : null,
                fromView: fromViewRef ? fromViewRef.instance : null,
                toViewClass: toViewRef.viewClass,
                toView: toViewRef.instance,
            },
            true
        ).trigger(this);

    // Checking if event wasn't cancelled
    if (event.isDefaultPrevented()) return null;

    push.call(this, fromViewRef, toViewRef, 0, transition);
}

function popView(transition) {
    if (this.viewsStack.length == 0) throw new Error("Popping from an empty stack!");

    // Getting ref of the view on top of the stack
    var fromViewRef = _.last(this.viewsStack),
        // Getting ref of the view below current one
        toViewRef = this.viewsStack.length > 1 ? this.viewsStack[this.viewsStack.length - 2] : null,
        // Creating viewChanging event object and triggering it
        event = createEvent(
            "viewChanging",
            {
                action: "pop",
                fromViewClass: fromViewRef.viewClass,
                fromView: fromViewRef.instance,
                toViewClass: toViewRef ? toViewRef.viewClass : null,
                toView: toViewRef ? toViewRef.instance : null,
            },
            true
        ).trigger(this);

    // Checking if event wasn't cancelled
    if (event.isDefaultPrevented()) return;

    // Popping top view
    pop.call(this, fromViewRef, toViewRef, 1, transition);
}

function popAll(transition) {
    if (this.viewsStack.length == 0) throw new Error("Popping from an empty stack!");

    // Getting ref of the view on top of the stack
    var fromViewRef = _.last(this.viewsStack),
        // Creating viewChanging event object and triggering it
        event = createEvent(
            "viewChanging",
            {
                action: "popAll",
                fromViewClass: fromViewRef.viewClass,
                fromView: fromViewRef.instance,
                toViewClass: null,
                toView: null,
            },
            true
        ).trigger(this);

    // Checking if event wasn't cancelled
    if (event.isDefaultPrevented()) return;

    // Popping top view
    pop.call(this, fromViewRef, null, this.viewsStack.length, transition);
}

function popAllButTop(transition) {
    if (this.viewsStack.length == 0) throw new Error("Popping from an empty stack!");

    // Getting ref of the view on top of the stack
    var fromViewRef = _.last(this.viewsStack),
        // Getting ref of the view at top
        toViewRef = this.viewsStack.length > 1 ? this.viewsStack[0] : null,
        // Creating viewChanging event object and triggering it
        event = createEvent(
            "viewChanging",
            {
                action: "pop",
                fromViewClass: fromViewRef.viewClass,
                fromView: fromViewRef.instance,
                toViewClass: null,
                toView: null,
            },
            true
        ).trigger(this);

    // Checking if event wasn't cancelled
    if (event.isDefaultPrevented()) return;

    // Popping top view
    pop.call(this, fromViewRef, toViewRef, this.viewsStack.length - 1, transition);
}

function replaceView(view, viewOptions, transition) {
    if (this.viewsStack.length == 0) throw new Error("Replacing on an empty stack!");

    // Getting ref of the view on top of the stack
    var fromViewRef = _.last(this.viewsStack),
        // Creating new view instance if it is necessary
        toView = _.isFunction(view) ? new view(viewOptions) : view,
        // Creating new view ref
        toViewRef = { instance: toView, viewClass: toView.constructor, options: viewOptions },
        // Creating viewChanging event object and triggering it
        event = createEvent(
            "viewChanging",
            {
                action: "replace",
                fromViewClass: fromViewRef.viewClass,
                fromView: fromViewRef.instance,
                toViewClass: toViewRef.viewClass,
                toView: toViewRef.instance,
            },
            true
        ).trigger(this);

    // Checking if event wasn't cancelled
    if (event.isDefaultPrevented()) return null;

    // Pushing new view on top
    push.call(this, fromViewRef, toViewRef, 1, transition);
}

function replaceAll(view, viewOptions, transition) {
    if (this.viewsStack.length == 0) throw new Error("Replacing on an empty stack!");

    // Getting ref of the view on top of the stack
    var fromViewRef = _.last(this.viewsStack),
        // Creating new view instance if it is necessary
        toView = _.isFunction(view) ? new view(viewOptions) : view,
        // Creating new view ref
        toViewRef = { instance: toView, viewClass: toView.constructor, options: viewOptions },
        // Creating viewChanging event object and triggering it
        event = createEvent(
            "viewChanging",
            {
                action: "replaceAll",
                fromViewClass: fromViewRef.viewClass,
                fromView: fromViewRef.instance,
                toViewClass: toViewRef.viewClass,
                toView: toViewRef.instance,
            },
            true
        ).trigger(this);

    // Checking if event wasn't cancelled
    if (event.isDefaultPrevented()) return null;

    // Pushing new view on top
    push.call(this, fromViewRef, toViewRef, this.viewsStack.length, transition);
}

function popActionsQueue() {
    this.actionsQueue.splice(0, 1);
    if (this.actionsQueue.length > 0) {
        var action = this.actionsQueue[0],
            args = Array.prototype.slice.call(action.args);
        switch (action.fn) {
            case "pushView":
                pushView.apply(this, args);
                break;
            case "popView":
                popView.apply(this, args);
                break;
            case "popAll":
                popAll.apply(this, args);
                break;
            case "replaceView":
                replaceView.apply(this, args);
                break;
            case "replaceAll":
                replaceAll.apply(this, args);
                break;
        }
    }
}

let StackNavigator = Backbone.View.extend(
    /** @lends BackStack.StackNavigator */
    {
        /**
         * @name StackNavigator#viewChanging
         * @event
         * @param {Object} e
         * @param {Boolean} [e.cancelable=true]
         */

        /**
         * An array with all the view refs on the stack.
         */
        viewsStack: null,

        /**
         * View on top of the stack.
         */
        activeView: null,

        /**
         * Default push transition effect.
         */
        defaultPushTransition: null,

        /**
         * Default pop transition effect.
         */
        defaultPopTransition: null,

        /**
         * Queue of actions to be executed on the stack.
         */
        actionsQueue: null,

        /**
         * Initializes StackNavigator.
         *
         * @param {Object} options This is a Backbone.View options hash that can have popTransition and pushTransition
         * properties that can be initiated for this instance of navigator.
         *
         * @constructs
         * */
        initialize: function (options) {
            // Setting default styles
            this.$el.css({ overflow: "hidden" });

            // Setting new viewsStack array
            this.viewsStack = [];

            // Setting new queue of actions
            this.actionsQueue = [];

            // Setting default pop transition
            if (options.popTransition) this.defaultPopTransition = options.popTransition;

            // Setting default push transition
            if (options.pushTransition) this.defaultPushTransition = options.pushTransition;
        },

        pushInitialView: function (view, viewOptions, transition) {
            // Pushing current action to the queue
            this.actionsQueue.push({ fn: "pushView", args: arguments });

            if (this.actionsQueue.length == 1) pushView.call(this, view, viewOptions, transition);
        },

        /**
         * Pushes new view to the stack.
         *
         * @param {Backbone.View || Backbone.ViewClass} view View class or view instance to be pushed to the stack.
         * @param {Object} viewOptions Options to be passed if view is contructed by StackNavigator.
         * @param {Effect} transition Transition effect to be played when pushing new view.
         */
        pushView: _.throttle(
            function (view, viewOptions, transition) {
                this.pushViewImmediate(view, viewOptions, transition);
            },
            400,
            { trailing: false }
        ),

        pushViewImmediate: function (view, viewOptions, transition) {
            // Pushing current action to the queue
            this.actionsQueue.push({ fn: "pushView", args: arguments });

            if (this.actionsQueue.length == 1) pushView.call(this, view, viewOptions, transition);
        },

        /**
         * Pops an active view from a stack and displays one below.
         *
         * @param {Effect} transition Transition effect to be played when popping new view.
         */
        popView: _.throttle(
            function (transition) {
                // Pushing current action to the queue
                this.actionsQueue.push({ fn: "popView", args: arguments });

                if (this.actionsQueue.length == 1) popView.call(this, transition);
            },
            400,
            { trailing: false }
        ),

        /**
         * Pops all views from a stack and leaves empty stack.
         *
         * @param {Effect} transition Transition effect to be played when popping views.
         */
        popAll: function (transition, exceptFirst) {
            // Pushing current action to the queue
            this.actionsQueue.push({ fn: "popAll", args: arguments });

            if (exceptFirst) {
                if (this.actionsQueue.length == 1) popAllButTop.call(this, transition);
            } else {
                if (this.actionsQueue.length == 1) popAll.call(this, transition);
            }
        },

        /**
         * Replaces view on top of the stack, with the one passed as a view param.
         *
         * @param {Backbone.View || Backbone.ViewClass} view View class or view instance to be pushed on top of the stack instead of current one.
         * @param {Object} viewOptions Hash with options to be passed to the view, if view param is not an instance.
         * @param {Effect} transition Transition effect to be played when replacing views.
         */
        replaceView: function (view, viewOptions, transition) {
            // Pushing current action to the queue
            this.actionsQueue.push({ fn: "replaceView", args: arguments });

            if (this.actionsQueue.length == 1) replaceView.call(this, view, viewOptions, transition);
        },

        /**
         * Replaces all of the views on the stack, with the one passed as a view param.
         *
         * @param {Backbone.View || Backbone.ViewClass} view View class or view instance to be pushed on top of the stack.
         * @param {Object} viewOptions Hash with options to be passed to the view, if view param is not an instance.
         * @param {Effect} transition Transition effect to be played when replacing views.
         */
        replaceAll: function (view, viewOptions, transition) {
            // Pushing current action to the queue
            this.actionsQueue.push({ fn: "replaceAll", args: arguments });

            if (this.actionsQueue.length == 1) replaceAll.call(this, view, viewOptions, transition);
        },
    }
);


export default {
    StackNavigator,
    Effect,
    NoEffect,
    SlideEffect,
    FadeEffect,
}
