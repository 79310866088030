/**
 * Retrieves the CSRF token from either the cookie or from the hidden csrfmiddlewaretoken input.
 * @returns {string|null} The CSRF token, or null if it cannot be found.
 */
export function getCSRFToken() {
    const csrfToken = getCookie('csrftoken');
    if (csrfToken?.length > 0) {
        return csrfToken;
    }
    else {
        return document.querySelector("input[name=csrfmiddlewaretoken]")?.value;
    }
}


/**
 * Adds a CSRF token to an AJAX request.
 *
 * @param {XMLHttpRequest} xhr - The XMLHttpRequest object.
 * @param {String} method - The HTTP method of the request.
 * @param {String} url - The URL of the request.
 */
export function addCSRFTokenToAjaxRequest(xhr, method, url) {
    const safeMethod = /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
    const isSameOrigin = sameOrigin(url);

    if (!safeMethod && isSameOrigin) {
        const csrfToken = getCSRFToken();
        if (csrfToken) {
            xhr.setRequestHeader("X-CSRFToken", csrfToken);
        }
    }
}


/**
 * Stores the CSRF token from the response in App.config.
 * @param {XMLHttpRequest} xhr - The XMLHttpRequest object.
 * @param {Object} appConfig - The App.config object.
 */
export function storeCSRFTokenFromResponse(xhr, appConfig) {
    const token = xhr.getResponseHeader("X-Twistle-CSRFToken");
    if (!token) {
        return;
    }

    // only set the token if we didn't have one to prevent session re-initialization.
    const currentToken = window.localStorage.getItem("csrfToken");
    if (currentToken) {
        return;
    }

    appConfig?.setLocalSetting("csrfToken", token);
}

/**
 * Retrieves the value of a cookie by its name.
 *
 * @param {string} name - The name of the cookie.
 * @returns {string|null} The value of the cookie, or null if the cookie is not found.
 */
function getCookie(name) {
    if (!document.cookie || document.cookie == '') {
        return null;
    }

    const cookies = document.cookie.split(';').map(c => c.trim());

    for (const cookie of cookies) {
        const parts = cookie.split('=');
        if (parts[0].trim() === name) {
            return decodeURIComponent(parts[1]);
        }
    }

    return null;
}


/**
 * Checks if the given URL is from the same origin as the current window's location.
 * @param {string} url - The URL to check.
 * @returns {boolean} - Returns true if the URL is from the same origin, otherwise false.
 */
function sameOrigin(url) {
    // url could be relative or scheme relative or absolute
    const host = window.location.host;
    // host + port
    const protocol = window.location.protocol;
    const sr_origin = '//' + host;
    const origin = protocol + sr_origin;
    // Allow absolute or scheme relative URLs to same origin
    return (url === origin || url.slice(0, origin.length + 1) === origin + '/') || (url === sr_origin || url.slice(0, sr_origin.length + 1) === sr_origin + '/') ||
    // or any other URL that isn't scheme relative or absolute i.e relative.
    !(/^(\/\/|http:|https:).*/.test(url));
}