import _ from 'underscore'
import $ from 'jquery'
import Backbone from 'backbone'

import {
    extractContactAvatar,
    postRenderImages,
} from 'util/twistle'

import { nullifyBlankObject  } from "util/core"
import { renderTemplate } from 'util/template_renderer'

import photoUploadTpl from 'profile/photo_upload'

import Webcam from "webcamjs"
import Contact from 'app/models/contact'

// Renders the conversation detail view, used for viewing a conversation, or starting a new one
App.PhotoUploadView = Backbone.View.extend({
    events: {
        'click .profile_picture_webcam_button': 'showWebCam',
        'click .profile_webcam_take_button': 'webCamTakePicture',
        'click .profile_webcam_cancel_button': 'webCamCancel',
        'click .profile_picture_embed_camera_button': 'embedPhotoTake'
    },
    options: {
        setAsProfilePhoto: false, //whether to set uploads as the profile pic for the current user
        setAsProfilePhotoForUser: undefined, // user object of someone else to set the profile pic for (typically custodee)
        photoSeqNum: "", // current ID of uploaded / taken photo
        currentPhotoUrl: "",
        userDetails: false,  // pass in a hash with details (firstname, lastname, specialty) to display next to the photo (optional)
        onPhotoComplete: false,
        photoUploadTitle: "Update your profile photo",
    },
    initialize: function() {
        var self = this;
        self.render();
        App.bind("app:embedded_photo_upload_complete", self.setProfileImage, self);
    },

    onClose: function () {
        var self = this;
        self.cleanupWebCam();
    },

    render: function() {
        var self = this;

        self.options.webcam_id = "photo_webcam_" + new Date().getTime();
        let avatarAccount = (self.options.setAsProfilePhotoForUser ?
            new Contact({username:self.options.setAsProfilePhotoForUser}) : App.account);
        self.options.avatar = extractContactAvatar(avatarAccount);
        self.options.avatar.exists = true; // always put the null profile image (and not the initials) in here
        self.options.avatar.overrideImageURL = nullifyBlankObject(self.options.currentPhotoUrl);
        self.options.supportsWebCam = App.isDesktop();

        let tpl = renderTemplate(
            photoUploadTpl,
            self.options,
            App.account.get("locale")
        );
        self.setElement(tpl);

        let spinnerElt = self.$(".profile_picture_display");

        self.$(".profile_picture_upload_button").twistle_attachments({
            picUpload: true,
            setAsProfilePhoto: self.options.setAsProfilePhoto,
            setAsProfilePhotoForUser: self.options.setAsProfilePhotoForUser,
            useWrap: true,
            uploadBegin: function(evt){
                spinnerElt.spin();
            },
            upload_success: function(evt, fileData) {
                spinnerElt.spin({stop:true});
                self.photoUploadComplete(evt, fileData);
            },

            upload_failure: function() {
                spinnerElt.spin({stop:true});
                self.photoUploadFailed();
            },

            upload_type_failure: function() {
                spinnerElt.spin({stop:true});
                self.photoUploadWrongType();
            }
        });

        if (App.config.get("embeddedDialogMode")) {
            setTimeout(function () {
                self.$(".profile_photo_actions").hide();
                self.$(".profile_photo_actions_embedded").show();
            }, 0);
        }

        postRenderImages(self.$el);

        _.bindAll.apply(_, [this].concat(_.functions(this)));
        return this;
    },

    showWebCam: function(evt) {
        var self = this;

        self.$(".profile_webcam_display").show();
        self.$(".profile_webcam_overlay").hide();
        self.$(".profile_picture_display").hide();
        self.$(".profile_picture_upload_button").hide();
        self.$(".profile_picture_webcam_button").hide();

        Webcam.set({
            width: 888, // live preview size
            height: 500,
            dest_width: 888, // device capture size
            dest_height: 500,
            crop_width: 500,
            crop_height: 500,
            image_format: 'jpeg',
            jpeg_quality: 90,
            force_flash: false,
            flip_horiz: true,
            fps: 45,
            swfURL: '/converse/js/vendor/jpegcam/webcam.swf',
            upload_name: "object_file"
        });


        Webcam.attach('#' + self.options.webcam_id);

        App.trigger("app:webcam_shown");
    },

    webCamTakePicture: function(evt) {
        var self = this;
        self.$(".profile_webcam_overlay").show();
        window.setTimeout(function(){
            Webcam.snap(
                function (data_uri) {
                    var url = '/attachment/Upload';

                    if (self.options.setAsProfilePhoto) {
                        url += '?set_as_photo=1';
                        if (self.options.setAsProfilePhotoForUser && self.options.setAsProfilePhotoForUser.length > 0) {
                            url += '&set_as_photo_for_user=' + self.options.setAsProfilePhotoForUser;
                        }
                    }

                    Webcam.upload(data_uri, url, function (code, text) {
                        self.webcamTakePictureSuccess(text);
                    });
                }
            )
        },50);
    },

    webcamTakePictureSuccess: function(responseMessage) {
        var self = this;
        var resp = $.parseJSON(responseMessage);
        self.$(".profile_webcam_display").hide();
        this.$(".profile_webcam_overlay").hide();
        self.$(".profile_picture_display").show();
        self.$(".profile_picture_upload_button").show();
        self.$(".profile_picture_webcam_button").show();
        self.options.photoSeqNum = resp.seqnum;
        App.trigger("app:webcam_closed");
        self.cleanupWebCam();
        self.setProfileImage(resp);
    },

    webCamCancel: function(evt) {
        var self = this;
        self.$(".profile_webcam_display").hide();
        self.$(".profile_webcam_overlay").hide();
        self.$(".profile_picture_display").show();
        self.$(".profile_picture_upload_button").show();
        self.$(".profile_picture_webcam_button").show();
        self.cleanupWebCam();
        App.trigger("app:webcam_closed");
    },

    cleanupWebCam: function () {
        Webcam?.reset();
    },

    embedPhotoTake: function (evt) {
        const self = this;
        App.trigger("app:embedded_dialog_photo_request", self.options);
    },

    photoUploadComplete: function(evt, fileData) {
        var attachmentDataList = this.$(".profile_picture_upload_button").twistle_attachments("attachments", true);
        var self = this;
        if(attachmentDataList.length) {
            var attachData = attachmentDataList[0];
            self.setProfileImage(attachData);
        }
        self.$(".profile_picture_upload_button").twistle_attachments("reset");
    },

    photoUploadFailed: function(evt, data) {
        alert("Photo Upload Failed - Please Try Again");
        this.$(".profile_picture_upload_button").twistle_attachments("reset");
    },

    photoUploadWrongType: function(evt, data) {
        alert("You need to upload a valid image file (JPEG, PNG, or GIF)");
        this.$(".profile_picture_upload_button").twistle_attachments("reset");
    },

    getPhotoSeqNum: function() {
        return this.options.photoSeqNum;
    },
    setProfileImage: function(attachData){
        var self = this;
        App.unbind("app:photo_upload_cropped", self.setProfileImage);
        self.options.photoSeqNum = attachData.seqnum;

        setTimeout(function() {
            var url = "/attachment/View?size=prf&seqnum=" + attachData.seqnum;
            if (self.options.setAsProfilePhotoForUser){
                url += "&foruser=" + self.options.setAsProfilePhotoForUser;
            }

            self.$(".profile_picture_display img.profile_image").attr("src", url)
                .bind("load", function(){
                                      App.trigger("app:profile_photo_update_loaded");
                                  });
        }, 300);

        if(self.options.setAsProfilePhoto && !self.options.setAsProfilePhotoForUser){
            App.account.fetch({
                success: function () {
                    App.trigger("app:profile_photo_updated", App.account.get("username"), attachData.seqnum);
                }
            });
        }

        if(self.options.onPhotoComplete){
            self.options.onPhotoComplete(attachData);
        }
    }
});
