export const Specialties = [
    "Abdominal Radiology",
    "Addiction Psychiatry",
    "Adolescent Medicine",
    "Allergy and Immunology",
    "Anesthesiology",
    "Bariatric Surgery",
    "Cardiology",
    "Cardiothoracic Surgery",
    "Child Neurology",
    "Child and Adolescent Psychiatry",
    "Clinical Neurophysiology",
    "Colon and Rectal Surgery",
    "Congenital/Pediatric Cardiothoracic Surgery",
    "Craniofacial Surgery",
    "Critical Care Medicine",
    "Dermatology",
    "Electrophysiology",
    "Dermatopathology",
    "Emergency Medicine",
    "Endocrinology",
    "Endovascular Surgical Neuroradiology",
    "Endovascular Surgery",
    "Family Medicine",
    "Female Pelvic Medicine and Reconstructive Surgery",
    "Forensic Psychiatry",
    "Gastroenterology",
    "General Surgery",
    "Genetics",
    "Geriatric Medicine",
    "Hand Surgery",
    "Hematology",
    "Hematology and Oncology",
    "Infectious Disease",
    "Internal Medicine",
    "Internal Medicine/Pediatrics",
    "Interventional Cardiology",
    "Neonatal-Perinatal Medicine",
    "Nephrology",
    "Neurology",
    "Neuromuscular Medicine",
    "Neuroophthalmology",
    "Neuropathology",
    "Neuroradiology",
    "Neurosurgery",
    "Nuclear Medicine",
    "Nuclear Radiology",
    "Obstetrics and Gynecology",
    "Oncology",
    "Ophthalmology",
    "Orthopaedic Surgery",
    "Otolaryngology",
    "Pain Medicine",
    "Pathology",
    "Pediatric Anesthesiology",
    "Pediatric Cardiology",
    "Pediatric Critical Care Medicine",
    "Pediatric Emergency Medicine",
    "Pediatric Endocrinology",
    "Pediatric Gastroenterology",
    "Pediatric Hematology/Oncology",
    "Pediatric Infectious Diseases",
    "Pediatric Nephrology",
    "Pediatric Orthopaedics",
    "Pediatric Otolaryngology",
    "Pediatric Pathology",
    "Pediatric Pulmonology",
    "Pediatric Radiology",
    "Pediatric Rheumatology",
    "Pediatric Sports Medicine",
    "Pediatric Surgery",
    "Pediatric Transplant Hepatology",
    "Pediatric Urology",
    "Pediatrics",
    "Physical Medicine and Rehabilitation",
    "Plastic & Reconstructive Surgery",
    "Preventive Medicine",
    "Psychiatry",
    "Pulmonary",
    "Pulmonary/Critical Care",
    "Radiation Oncology",
    "Radiology",
    "Rheumatology",
    "Sleep Medicine",
    "Spinal Cord Injury Medicine",
    "Sports Medicine",
    "Stroke",
    "Surgical Critical Care",
    "Thoracic Surgery",
    "Transplant Hepatology",
    "Transplant Surgery",
    "Urology",
    "Vascular Surgery",
    "Vascular and Interventional Radiology"
];
