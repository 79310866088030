import {
    cleanPhone,
    dateDiffAsAge,
    parseDate,
    passwordStrength,
} from 'util/twistle'
// eslint-disable-next-line import/no-unresolved
import { isValidPhoneNumber } from 'libphonenumber-js'

export function validateField(val, type, params) {
    type = type || "unknown";
    var validatorFunc = Validators["validate" + type.charAt(0).toUpperCase() + type.substr(1)];
    if(validatorFunc) {
        return validatorFunc.apply(this, [val, params]);
    }
    else {
        return (val && val.length > 0) || false;
    }
}

export function validatePhoneNumber(text, country_codes) {
    return Validators.validatePhone(text, country_codes);
}

export function validateEmailAddress(email_string) {
    const emailPattern = /^[A-Z0-9._'%+-]+@[A-Z0-9_-]+\.[A-Z0-9_-]{2,}(?:\.[A-Z0-9_-]{2,})*$/i;
    return emailPattern.test(email_string);
}

export function validateNumeric(number_string) {
    var numberPattern = /^[0-9]/;
    return numberPattern.test(number_string);
}

let Validators = {
    validateDate: function(date_string) {
        var validformat = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
        //Basic check for format validity
        if(!validformat.test(date_string)) {
            return false;
        }
        else {//Detailed check for valid date ranges
            var date_parts = date_string.split("/");
            var monthfield = parseInt(date_parts[0], 10);
            var dayfield = parseInt(date_parts[1], 10);
            var yearfield = parseInt(date_parts[2], 10);
            // make sure year is between 1900 and now
            var thisYear = new Date().getFullYear();
            if (yearfield < 1900 || yearfield > thisYear) {
                return false;
            }
            var dayobj = new Date(yearfield, monthfield - 1, dayfield);
            if((dayobj.getMonth() + 1 !== monthfield) || (dayobj.getDate() !== dayfield) || (dayobj.getFullYear() !== yearfield)) {
                return false;
            }
            return true;
        }
    },

    validateEmail: function(email_string) {
        return validateEmailAddress(email_string);
    },

    validateNumeric: function(number_string) {
        var numberPattern = /^[0-9]/;
        return numberPattern.test(number_string);
    },

    validatePhone: function(phone_string, counry_codes) {
        const clean_phone = cleanPhone(phone_string);
        for (let country_code of counry_codes || ["US"]) {
            if (isValidPhoneNumber(clean_phone, country_code)) return true;
        }
        return false;
    },

    validateEmailOrPhone: function(email_or_phone_string, country_codes) {
        return Validators.validateEmail(email_or_phone_string) || Validators.validatePhone(email_or_phone_string, country_codes);
    },

    validateUszip: function(zip_string) {
        return (/^\d{5}$/).test(zip_string.replace(/\D/g, ''));
    },

    validateAge: function(date_string, params) {
        // you better pass this a valid date, and params.minAge !!!
        try{
            return dateDiffAsAge(new Date(), parseDate(date_string)) >= (params.minAge || 0);
        }
        catch (e){
            return false;
        }
    },

    validatePasswordStrength: function(pw){
        return passwordStrength(pw).value >= 1;
    },

    validatePasswordMatch: function(pw, params){
        //expects params.password to be the one to check against
        return pw.length > 0 && pw === (params.password || "");
    },

    validateCheckbox: function(val, params){
        // params.checkbox should be the jq handle
        return params.checkbox.is(":checked");
    },

    validateAllowAny: function(str){
        return true;
    },

    validateAllowBlank: function(str){
        return typeof(str) !== "undefined";
    },
};
