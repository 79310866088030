/**
 * Checks if the client version is outdated based on the server response.
 * If the server responded with a client version greater than the current version,
 * triggers an event to notify that a new client version is available.
 *
 * @param {XMLHttpRequest} xhr - The XMLHttpRequest object used for the request.
 * @param {Object} appConfig - The App.config object.
 * @returns {boolean} - Whether the client version is outdated.
 */
export function clientVersionIsOutdated(xhr, appConfig) {
    const clientVersion = appConfig?.get("clientVersion");
    const serverVersion = xhr.getResponseHeader("Twistle-Client-Version");

    if (!clientVersion || !serverVersion) {
        return false;
    }

    return parseInt(serverVersion, 10) > clientVersion;
}