import $ from 'jquery'
const Mustache = require("mustache");
import _ from "underscore"
import { localize, setAppObject } from 'util/localize'
import { trimQuotes } from 'util/string'

let _mustacheTemplates = {};
let _mustacheFunctions = {};
let _app = null;


/**
 * Deletes all registered Mustache templates from the template_renderer.
 * Since these templates have all been removed from your HTML page, you
 * ought not use this unless you know what you're doing :)
 */
export function clearTemplateCache() {
    _mustacheFunctions = {};
    _mustacheTemplates = {};
}


/**
 * Returns an object that contains all registered Mustache templates.
 * The returned object's keys are the id attribute values of the registered
 * templates.
 */
export function mustacheTemplates() {
    return _mustacheTemplates;
}


/**
 * Renders the specified template with the specified template data and optional language.
 * Notes:
 * - template may either be a string or a function.
 * - templateData will be merged with the contents of App.config.
 * - If language is unspecified, then it is assumed to be 'en'.
 * @param  {string|function} template Either a string representing the name of a Mustache template or a Handlebars template function.
 * @param  {Object} templateData The data that will be passed to the template at render time.
 * @param  {string?} language The locale that will be used to render this template.
 */
export function renderTemplate(template, templateData, language) {
    if (_app == null) {
        throw "App must be set in loadTemplatesForApp() before calling renderTemplate()"
    }

    let extendedParams = _.extend({}, ((_app.config && _app.config.toJSON()) || {}), (templateData || {}));

    // if the caller passed in a function (i.e. a Handlebars template),
    // then just render it with our extended params as-is. Our HBS templates
    // take care of localization in a different way than Mustache templates.
    if (typeof template === 'function') {
        return styleContent($(template(extendedParams)));
    }

    let tpl = _mustacheFunctions[template];
    if (tpl == null) {
        throw `Unregistered template: ${template}`;
    }

    extendedParams['_locale'] = language || _app.account.get("locale");

    let $content = $(tpl(extendedParams));
    return $content;
}


/**
 * Convert `data-style` attributes into actual styled elements in a CSP-compliant manner
 * @param  {Object} $generatedContent A jQuery element object
 */
export function styleContent($generatedContent) {
    $generatedContent.find("[data-style]").add($generatedContent.filter("[data-style]")).each(function(idx, el) {
        let dataStyle = $(el).data("style");
        el.style = dataStyle;
    });
    return $generatedContent;
}


/**
 * Loads Mustache templates from the supplied element and makes them available
 * to callers of the function renderTemplate().
 *
 * @param  {Object} element An HTML element. You will likely pass `document` here.
 * @param  {App} app The App module. Expected to respond to `config`, which is expected to respond to `toJSON()`, and `account`.
 */
export function loadTemplatesForApp(element, app) {
    _app = app;

    setAppObject(_app);

    const scripts = $(element).find('script[type="text/html"]').filter((idx, elt) => {
        const $elt = $(elt);
        return $elt.html().length > 0 && $elt.attr('id').length > 0
    });

    for (const script of scripts) {
        const $script = $(script);
        addTemplate($script.attr('id'), $script.html().trim());
        $script.remove();
    }
}


/**
 * Adds the specified template to the template cache.
 * @param  {string} name the name/id of the template
 * @param  {string} templateString A string representation of the template
 */
function addTemplate(name, templateString) {
    if (_mustacheTemplates[name]) {
        console.error(`Error: template already exists ${name}`);
    }
    else {
        _mustacheTemplates[name] = templateString;
        _mustacheFunctions[name] = (data) => {
            data = data || {};
            let result = Mustache.render(
                _mustacheTemplates[name],
                {
                    ...data,
                    loc: function() {
                        return function(text, render) {
                            const {keyIgnoreWarnings, defaultValue} = parseLocTag(text);
                            const localized = localize(keyIgnoreWarnings, defaultValue, {locale: this._locale});
                            return render(localized);
                        };
                    }
                },
                _mustacheTemplates
            );
            return $(result.trim());
        };
    }
}

function parseLocTag(text) {
    let tagString = `<elt ${text} />`;
    let documentFragment = $("<div></div>").append(tagString);

    const keyIgnoreWarnings = documentFragment.children()?.[0].attributes.key.value;
    const defaultValue = documentFragment.children()?.[0].attributes.value.value;

    // TODO: add a validator that checks localizable strings for correctness using these values:
    // const comment = documentFragment.children[0].attributes.comment.value;
    // const priority = parseInt(documentFragment.children[0].attributes.priority.value);

    return {keyIgnoreWarnings, defaultValue};
}