/**
* Return a string with <suffix> removed from the end.
* If <suffix> is not found, the original string is returned
* @param  {string} str The string to chomp.
* @param  {string} suffix The string to chomp off str.
*/
export function chomp(str, suffix) {
    var idx = str.indexOf(suffix, str.length - suffix.length);
    if (idx !== -1) {
        return str.substring(0, idx);
    }
    return str.toString();
}

// 
/**
* Truncate a string at the closest word boundary below the given length, adding ellipses.
* @param  {string} str The string to truncate
* @param  {number} length The length at which to truncate the string
*/
export function truncate(str, length) {
    if (str.length <= length) {
        return str;
    }
    return str.substr(0, length).replace(/\s\S*$/, "...");
}

/**
 * Remove spaces from start, end, or repeating within (optionally not replacing repeating spaces with a single space).
 * @param  {string} str The string to trim
 * @param  {boolean} removeRepeating Should repeating spaces be removed?
 */
export function trimAll(str, removeRepeating) {
    return str.replace(/\n /,"\n")
              .replace(/[ ]{2,}/gi, (removeRepeating) ? "" : " ")
              .replace(/(^\s*)|(\s*$)/gi,"");
}
/**
 * Returns true if the string contains no characters after whitespace is removed.
 * @param  {string} str
 */
export function isBlank(str) {
    return str.replace(/\s/g,"") === "";
}

var escapeMap = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': '&quot;',
    "'": '&#39;'
};

var escapeMapNoQuote = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;"
};

// HTML-escape a string - credit to Mustache.js

export function escapeHTML(str, quote=true) {
    return str?.replace(/&(?!\w+;)|[<>"']/g, function (s) {
        if (quote) {
            return escapeMap[s] || s;
        }
        return escapeMapNoQuote[s] || s;
    }) ?? null;
}

/**
 * Trims " and ' characters from the start and end of the string.
 * @param  {string} str
 */
export function trimQuotes(str) {
    let trimmed = str;
    if (trimmed[0] == "\"" || trimmed[0] == "'") {
        trimmed = trimmed.substring(1);
    }

    if (trimmed[trimmed.length - 1] == "\"" || trimmed[trimmed.length - 1] == "'") {
        trimmed = trimmed.slice(0,-1);
    }

    return trimmed;
}