import $ from 'jquery'

(function($) {

    var methods = {
        init: function(options) {

            // for settings we might default at some point
            var settings = {
                alwaysShow: false,
                onRemove: function(event, el) {

                }

            };

            return this.each(function() {
                var $this = $(this);
                if(options) {
                    $.extend(settings, options);
                }

                var remove_button = $('<b class="removeButton"></b>');
                if(!settings.alwaysShow){
                    remove_button.hide();
                }
                remove_button.bind("click.removable", {
                    "this_removable": $this
                }, methods.remove);

                $this.append(remove_button);
                if(App && App.config && App.isTouch() && !settings.allowPressRemove){
                    remove_button.show(); // always show removables on touch UI, unless "press-remove" is turned on
                }
                else if (!settings.alwaysShow && settings.allowPressRemove && App.isTouch()) {
                    $this.on("click", methods.toggleRemoveButton);
                }
                else if(!settings.alwaysShow){
                    $this.hover(methods.hoverOn, methods.hoverOff);
                }
                $this.bind("twistle_removable.onRemove", settings.onRemove);

            });

        },

        destroy: function() {

            return this.each(function() {
                // do we need to unbind here?
            });

        },

        hoverOn: function() {
            $(this).addClass("twistle_removeable_hover");
            $(this).find("b.removeButton").show();
        },

        hoverOff: function() {
            $(this).removeClass("twistle_removeable_hover");
            $(this).find("b.removeButton").hide();
        },

        toggleRemoveButton:function(e){
            if (App.stopPropagation){
                App.stopPropagation(e);
            }
            else{
                e.stopPropagation();
            }

            $(this).toggleClass("twistle_removeable_hover");
            $(this).find("b.removeButton").toggle();
        },

        remove: function(event) {
            event.data.this_removable.trigger("twistle_removable.onRemove", [event, event.data.this_removable]);
            if(!event.data?.cancel_remove){
                event.data.this_removable.remove();
            }
        }

    };

    $.fn.twistle_removable = function(method) {

        if(methods[method]) {
            return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
        }

        if( typeof method === 'object' || !method) {
            return methods.init.apply(this, arguments);
        }

        $.error('Method ' + method + ' does not exist on jQuery.tooltip');


    };

}($));
