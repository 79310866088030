import jRespond from "jrespond"

/**
 * Sets up responsive breakpoints, along with callbacks to trigger the events "app:breakpoint_enter"
 * and "app:breakpoint_exit".
 * @param  {number} handheldExit=694 The maximum size of the handheld breakpoint.
 * @param  {number} tabletExit=1024 The maximum size of the tablet breakpoint.
 */
export function configureBreakpoints(handheldExit=694, tabletExit=1024) {
    const breakpoints = [
        {label: 'handheld', enter: 0, exit: handheldExit},
        {label: 'tablet', enter: (handheldExit+1), exit: tabletExit},
        {label: 'desktop', enter: (tabletExit+1), exit: 100000}
    ];

    App.jRes = jRespond(breakpoints);

    // when we resize past a breakpoint, broadcast events using our dispatcher, allowing views to self adjust (beyond what you could do w/ CSS)
    App.jRes.addFunc({
        breakpoint: "*",
        enter: function(bp) {
            App.trigger("app:breakpoint_enter", bp.entering);
        },
        exit: function(bp) {
            App.trigger("app:breakpoint_exit", bp.exiting);
        }
    });
}

export function isHandheld() {
    return App.jRes?.getBreakpoint() === "handheld";
}

export function isTablet() {
    return App.jRes?.getBreakpoint() === "tablet";
}

export function isDesktop() {
    return App.jRes?.getBreakpoint() === "desktop";
}