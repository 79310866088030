import $ from 'jquery';
import _ from 'underscore';
import "ajaxq";

import {
    addCSRFTokenToAjaxRequest,
    storeCSRFTokenFromResponse
} from 'network/csrf_token';

import {
    generateXHRAuthHeaders,
    storeSessionIDFromResponse
} from 'network/auth_headers';

import { clientVersionIsOutdated } from 'network/helpers';

import { showGrowlError} from 'util/alerts';

require('app/widgets/spin.js');

export function handleAjaxError(errorResp) {
    if (errorResp.status !== 0) {
        try
        {
            var errorJson = $.parseJSON(errorResp.responseText);

            if(errorJson.text.indexOf("Not Logged In") > -1)
            {
                App.trigger("app:logout");
            }
            else {
                showGrowlError(`Server error: ${errorJson.text}`);
            }
        } catch(e) {
            // nop?
        }
    }
}


export let ajax = {
    _loadingCount: 0,
    // ajax.request() fetches data from the server.  It uses the jquery ajax method
    // which means it is asynchronous.  That is, when this is called, the caller
    // shouldn't expect a result right away, but the onsuccessFunc will get called
    // when the results are finished being retrieved.  In reality, there should
    // also be a onerrorFunc, but I expect that this whole service will be rewritten
    // to make it more robust anyways, so skipping that for now.  For now, errors just
    // printed to the screen

    // The parameters are as follows:
    //   url is the server url that provides the service
    //   dataHash is the parameters as a { key:value, key:value, ...} object
    //   resultTypes is the expected results as a [ value, value, ... ] object
    //   onsuccessFunc is the function to invoke when the results are retrieved
    request: function(url, dataHash, resultTypes, onsuccessFunc, showLoading, onErrorFunc, submitButton, useGet, useQueueName) {
        var originalSubmitButtonText = {};
        if(showLoading) {
            if(ajax._loadingCount === 0) {
                $('#loadingIndicator').fadeIn(30);
            }
            ajax._loadingCount++;
        }
        if(submitButton) {
            var submitIsDisabled = submitButton.prop("disabled");
            if(submitIsDisabled || submitButton.hasClass("submit_processing")) {
                return false;
            }

            // Store text for each submit button
            _.each(submitButton, function(obj) {
                originalSubmitButtonText[obj.className] = $(obj).text();
            });

            submitButton.text("Just a sec...");
            if(submitButton.css("position") === "static"){
                submitButton.css("position", "relative");
            }
            submitButton.addClass("submit_processing")
                        .prop("disabled", true)
                        .spin({left:"8px",length:3,radius:1,lines:6,width:1.5,color:"#888"});
        }

        var ajaxOpts = {
            type: useGet ? "GET":"POST",
            url: formatURL(url),
            data: JSON.stringify(dataHash),
            contentType: "application/json",

            success: function(jsonMsg) {
                if(submitButton) {
                    submitButton.removeClass("submit_processing").prop("disabled", false);
                    // Restore text for each submit button
                    _.each(submitButton, function(obj) {
                         $(obj).text(originalSubmitButtonText[obj.className]);
                    });
                }
                if(showLoading) {
                    ajax._loadingCount--;
                    if(ajax._loadingCount === 0) {
                        $('#loadingIndicator').fadeOut(120);
                    }
                }

                if(jsonMsg.result && jsonMsg.result === "error") {
                    showGrowlError("An error occurred with the server's response.<br/><br/>" + ((jsonMsg.text) || "") + "!<br/><br/>Please try that again.");
                    if(jsonMsg.error && jsonMsg.error.indexOf("inconsistent") > -1) {
                        if(App.trigger) {
                            App.trigger("app:check_push_client");
                        }
                    }
                    return;
                }

                if(resultTypes) {
                    var i;
                    for(i = 0; i < resultTypes.length; i++) {
                        if(jsonMsg[resultTypes[i]] === undefined) {
                            showGrowlError("An error occurred with the server's response.<br/><br/> Please try that again.");
                            return;
                        }
                    }
                }

                if(onsuccessFunc) {(onsuccessFunc)(jsonMsg);
                }
            },

            error: function(jsonMsg) {
                if(submitButton) {
                    submitButton.removeClass("submit_processing").prop("disabled", false);
                    // Restore text for each submit button
                    _.each(submitButton, function(obj) {
                         $(obj).text(originalSubmitButtonText[obj.className]);
                    });
                }

                if(showLoading) {
                    ajax._loadingCount--;
                    if(ajax._loadingCount === 0) {
                        $('#loadingIndicator').fadeOut(30);
                    }
                }

                var errorFuncResult = false;

                // try to parse the response
                var errorRespJson;

                try{
                    errorRespJson = $.parseJSON(jsonMsg.responseText);
                }
                catch(e){
                    // ignore
                    errorRespJson = {};
                }

                if (errorRespJson.error == "NeedsVerification") {
                    const queryParams = window.location.search;
                    return window.location = `/account/confirm${queryParams}`;
                }

                if (onErrorFunc) {
                    errorFuncResult = (onErrorFunc)(jsonMsg, errorRespJson) || false;
                }

                if (!errorFuncResult) {
                    handleAjaxError(jsonMsg);
                }
            }
        };

        // if a queue name was specified, use ajaxq functionality (serializes requests to prevent out-of-order posts)
        if(useQueueName)
        {
            return $.ajaxq(useQueueName, ajaxOpts);
        }

        return $.ajax(ajaxOpts);

    }
};

$(document).ajaxSend(function(event, xhr, settings) {
    addCSRFTokenToAjaxRequest(xhr, settings.type, settings.url);

    const authHeaders = generateXHRAuthHeaders(App.account, App.config);
    for (let [key, value] of Object.entries(authHeaders)) {
        xhr.setRequestHeader(key, value);
    }

    // add clickstream data
    var clickstream = { events: [] };
    if (App.getClickstream) {
        clickstream = App.getClickstream()
        if (clickstream.events.length > 0) {
            var clickstream_query_param = "cs=" + encodeURIComponent(JSON.stringify(clickstream));
            // if the size of the encoded clickstream is close to 1K (URL size limit),
            // don't attempt to send it with a GET request--wait for the next POST
            if (settings.type === "GET" && clickstream_query_param.length < 1000) {
                settings.url += (settings.url.match(/\?/) ? '&' : '?') + clickstream_query_param;
                App.resetClickstream();
            } else if (settings.type === "POST") {
                if(settings.contentType === "application/json") {
                    // jam clickstream into data being sent to server
                    var jsonData = settings.data ? JSON.parse(settings.data) : {};
                    jsonData.cs = clickstream;
                    settings.data = JSON.stringify(jsonData);
                } else {
                    settings.data = (settings.data ? settings.data + '&' : '') + clickstream_query_param;
                }
                App.resetClickstream();
            }
        }
    }
});

// deal with certain response conditions
$(document).ajaxComplete(function(event, xhr, settings) {
    if (clientVersionIsOutdated(xhr, App.config)) {
        App.trigger("app:new_client_available");
    }

    storeSessionIDFromResponse(xhr, settings.url, App.config);
    storeCSRFTokenFromResponse(xhr, App.config);
});

// log/show ajax errors
let tws_ShowXHRConnectionError = _.throttle(function(){
    // throttle warnings about connection errors (e.g. at most show one every 5 seconds)
    // to protect against polluting the UI under flaky connections (or lots of xhr(s) that all failed at one)
    showGrowlError("Unable to connect to Twistle");
}, 6000, {trailing: false});

$(document).ajaxError(function(e, xhr, settings, thrownError) {
    if(!App || !App.config || !App.config?.get("isReloading"))
    {
        if( (xhr.status === 0 || thrownError === "" || (thrownError.indexOf && thrownError.indexOf("timeout") > -1)) &&
            (thrownError !== "abort") ){
            tws_ShowXHRConnectionError();
        }
    }
    var errorMsg = "url: " + settings.url + "\nstatus: " + xhr.status + "\nresponseText: " + xhr.responseText;
    App.logError(errorMsg);

});

function formatURL(url) {
    if (url.startsWith('/')) {
        return url;
    }
    else {
        console.warn('Path does not start with a "/". Please fix! ' + url);
        return '/' + url;
    }
}