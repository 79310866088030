/**
 * Returns the path to the image we use to represent different types of files.
 * @param  {string} fileType The type of file: image, twistle/form-definition, text/plain, etc.
 */
export function fileTypeToIconPath(fileType) {
    switch(fileType) {
        case "twistle/form-definition":
            return '/converse/images/file_icons/form.svg';
        case "twistle/workflow-definition":
            return '/converse/images/file_icons/workflow.svg';
        case "twistle/folder":
            return '/converse/images/file_icons/folder.svg';
        case "audio/amr":
        case "audio/mp3":
        case "audio/mpeg":
            return '/converse/images/file_icons/audio.svg';
        case "text/csv":
            return '/converse/images/file_icons/csv.svg';
        case "application/vnd.ms-excel":
            return '/converse/images/file_icons/excel.svg';
        case "text/html":
        case "text/plain":
            return '/converse/images/file_icons/text.svg';
        case "video/quicktime":
        case "video/3gpp":
        case "video/mp4":
            return '/converse/images/file_icons/video.svg';
        case "application/msword":
            return '/converse/images/file_icons/word.svg';
        case "application/zip":
        case "application/x-zip-compressed":
            return '/converse/images/file_icons/zip.svg';
        case "image":
            return '/converse/images/file_icons/image.svg';
        default:
            return '/converse/images/file_icons/misc.svg';
    }
}