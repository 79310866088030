import _ from 'underscore'
import $ from 'jquery'

import { addCSRFTokenToAjaxRequest } from 'network/csrf_token'

import {
    attachThumbSrc,
    guessMimeType,
    isRenderableVideoType,
    postRenderImages,
    raiseAlert,
    getFileIconHtml,
} from 'util/twistle'

import { renderTemplate } from 'util/template_renderer'

/****
 * An multi-file HTML5 attachment uploader
 */
(function($) {
    $.widget("ui.twistle_attachments", {
        // default options
        options: {
            hidden: true,
            useWrap: false,
            fileListUi: false,
            customFileUi: false,
            label:'Add File/Image',
            isMultiple: true,
            shareWithOrgId:false,
            currentUser: "",
            picUpload: false,
            fileElementClass: "",
            setAsProfilePhoto: false,
            setAsProfilePhotoForUser: undefined, // user object of someone else to set the profile pic for (typically custodee)
            uploadUrl: "/attachment/Upload",
            uploadFileName: "object_file",
            allowedTypes: undefined,
            additionalUploadParams: [],
            allowDrop: false,
            trimFileNames: true
        },
        _create: function() {
            const context = this,
                ChooseFileButtonUi = $('<a class="chooseFileButton tws-btn--primary' + ((this.options.picUpload) ? ' picUpload">' : '">') + context.options.label + '</a>'),
                FileUploadControlUi = `<input size="1" 
                                        name="${context.options.uploadFileName}" 
                                        class="fileUploadControl" 
                                        ${!context.options.isMultiple ? ' ' : 'multiple="1" '} 
                                        ${context.options.allowedTypes ? `accept="${context.options.allowedTypes}" ` : ' '}                                         
                                        label="${context.options.label}" 
                                        type="file"/>`;
            var FileListUi = '<ul class="fileList"/>';

            if(!context.options.useWrap) {
                ChooseFileButtonUi.append(FileUploadControlUi);
                context.element.html("").append(ChooseFileButtonUi).append(FileListUi);
                context.options.fileListUi = context.element.find(".fileList");
            }
            else {
                context.element.append(FileUploadControlUi);
                if(!context.options.fileListUi) {
                    context.element.append(FileListUi);
                    context.options.fileListUi = context.element.find(".fileList");
                }
            }

            // if the upload input is disabled, hide it (good for mobile safari)
            if(context.element.find(".fileUploadControl").prop("disabled")){
                context.element.hide();
                context.inputNotAvailable = true;
            }

            context.file_info = [];

            //bind our input change to the upload method
            var fileinput = this.element.find(".fileUploadControl");
            fileinput.bind('change', {
                "attach_plugin": this
            }, this.upload);

            if(context.options.allowDrop){
                context.element.on("drop", function(e){
                    context.element.removeClass("attachment_drag_over");
                    context.dropUpload(e);
                    e.stopPropagation();
                    e.preventDefault();
                });
                context.element.on("dragover", function(e){
                    context.element.addClass("attachment_drag_over");
                });
                context.element.on("dragleave", function(e){
                    context.element.removeClass("attachment_drag_over");
                });
            }

            context.element.hoverClass("hovered");
            context.options.fileListUi.hide();
        },
        isDisabled: function() {
            var context = this;
            return context.inputNotAvailable || false;
        },
        attachments: function(asMap) {
            var context = this, attach = [];
            context.options.fileListUi.find("li.fileElement,.library_attachment").each(function(idx, value) {
                var fileData = $(value).data("fileData");
                if(fileData.upload_success) {
                    if(asMap) {
                        attach.push(fileData);
                    }
                    else {
                        attach.push(fileData.seqnum);
                    }
                    if (!context.options.fileListUi.hasClass("center_attachments")) {
                        context.options.fileListUi.addClass("center_attachments");
                        context.options.fileListUi.show();
                    }
                }
            });

            return attach;
        },

        setAttachments: function(attachList, full) {
            var context = this;

            $.each(attachList, function(idx, val) {
                var fileElement = context._addAttachmentUi(val.description, val);
                context._showAttachmentSuccessUi(fileElement, val, full);
            });

            if(attachList && attachList.length > 0)
            {
                context.options.fileListUi.addClass("center_attachments");
                context.options.fileListUi.show();
            }

        },

        reset: function() {
            var context = this, fileinput = context.element.find(".fileUploadControl");
            fileinput.val(""); //resets the filelist too
            var upload_files = context.options.fileListUi.find("li.fileElement,.library_attachment");
            upload_files.remove();
            if(!context.options.picUpload){
                context.options.fileListUi.hide();
            }
        },

        dropUpload: function(event) {
            var context = this;
            if(!event.data)
            {
                event.data = {};
            }
            event.data.attach_plugin = this;
            context._html5Upload(this, event);
        },

        upload: function(event) {
            var context = event.data.attach_plugin;
            context._html5Upload(this, event);
        },

        _html5Upload: function(uiControl, event){
            var context = event.data.attach_plugin,
                picUpload = context.options.picUpload,
                setAsProfilePhoto = context.options.setAsProfilePhoto,
                upload_files = context.options.fileListUi,
                fileInputList = uiControl.files;


            if(!fileInputList &&  (event.originalEvent.dataTransfer && event.originalEvent.dataTransfer.files))
            {
                // make sure there are no folders - we don't support for now
                var folderExists;
                _.each(event.originalEvent.dataTransfer.items || [], function(item){
                    if(item.webkitGetAsEntry && item.webkitGetAsEntry().isDirectory)
                    {
                        alert("Sorry - you cannot upload folders to Twistle at this time.");
                        folderExists = true;
                    }
                });
                if(folderExists)
                {
                    return;
                }
                fileInputList = event.originalEvent.dataTransfer.files;
            }

            if(fileInputList && fileInputList.length)
            {
                upload_files.show();
                context._trigger("uploadStart", event);
            }
            else
            {
                console.log("HTML5 upload - nothing to upload!");
                return;
            }


            _.each(fileInputList, function(file){

                var xhr = new XMLHttpRequest();

                var filename = file.name;

                if(filename.length > 12 && context.options.trimFileNames) {
                    filename = filename.substr(0, 11) + "...";
                    var fileparts = file.name.split(".");
                    if(fileparts.length > 0) {
                        filename += fileparts[fileparts.length - 1];
                    }
                }

                var fileElement = context._addAttachmentUi(filename, {
                    upload_success: false
                }, xhr);

                var progressBar = fileElement.find(".fileProgress");

                context._trigger("uploadBegin", event);

                (function(file, xhr, fileElement, progressBar, context) {

                    console.log("Upload File:"+JSON.stringify(file));
                    // fix mimetype if neccessary
                    var fileType = file.type || "";

                    if(fileType.length === 0)
                    {
                        fileType = context._guessMimeType(file.name);
                    }

                    if(!context._fileTypeIsAllowed(fileType)){
                        context._trigger("upload_type_failure", {type: fileType});
                        fileElement.remove();
                        return;
                    }


                    // Update progress bar
                    xhr.upload.addEventListener("progress", function(evt) {
                        if(evt.lengthComputable) {
                            progressBar.width((evt.loaded / evt.total) * 62 + "%");
                            if((evt.loaded / evt.total) > 0.92) {
                                progressBar.addClass("fileProcessing");
                                progressBar.animate({
                                    width: "96%"
                                }, evt.total / 24, function() {
                                    $(this).hide();
                                });

                            }
                        }
                        // else {
                        //     // No data to calculate on
                        // }
                    }, uiControl);

                    // File upload complete event handler
                    xhr.addEventListener("load", function(evt) {
                        fileElement.removeClass("fileUploading");

                        var fileData = {};

                        try
                        {
                            fileData = $.parseJSON(this.responseText);
                        }
                        catch(ignore)
                        {
                            // no json in response
                        }

                        if(xhr.status === 200) {
                            if ((fileData.type !== "image" && picUpload ) ||
                                (fileData.error === "Wrong File Type")) {
                                context._trigger("upload_type_failure", evt);
                                fileElement.remove();
                            }
                            else
                            {
                                context._showAttachmentSuccessUi(fileElement, fileData);
                                // pass UI along
                                fileData.fileElement = fileElement;
                                context._trigger("upload_success", evt, fileData);
                            }
                        }
                        else {
                            fileElement.remove();

                            if(!fileData.error)
                            {
                                fileData.error = "Unknown error";
                            }
                            if(picUpload && fileData.error === "InvalidProfilePhoto"){
                                context._trigger("upload_type_failure", evt);
                            }
                            else {
                                context._trigger("upload_failure", evt, fileData);
                                context._handleUploadFailure(evt,fileData);
                            }
                        }
                        progressBar.width(0);

                    }, this);

                    xhr.addEventListener("error", function(evt){
                        fileElement.remove();
                        var fileData = {"error":"A network error occurred."};
                        context._trigger("upload_failure", evt, fileData);
                        context._handleUploadFailure(evt,fileData);
                    }, this);
                    xhr.addEventListener("abort", function(evt){
                        fileElement.remove();
                        var fileData = {"error":"The request was cancelled"};
                        context._trigger("upload_failure", evt, fileData);
                        context._handleUploadFailure(evt,fileData);
                    }, this);

                    let uploadEndpoint = context.options.uploadUrl;

                    xhr.open("post", uploadEndpoint, true);

                    // Set appropriate headers
                    //xhr.setRequestHeader("Content-Type", "multipart/form-data");
                    xhr.setRequestHeader("X-File-Name", file.fileName);
                    xhr.setRequestHeader("X-File-Size", file.fileSize);
                    xhr.setRequestHeader("X-File-Type", fileType);
                    if (App.config.get("noCookies")) {
                        xhr.setRequestHeader("X-Twistle-SessionId", window["twistle-session-id"]);
                    }

                    addCSRFTokenToAjaxRequest(xhr, "POST", App.config.get("baseUrl") + context.options.uploadUrl);

                    // Send the file (doh)
                    if(window.FormData) {//Many thanks to scottt.tw
                        var f = new window.FormData();
                        f.append(context.options.uploadFileName, file);
                        f.append("foruser", "self");
                        if(picUpload && setAsProfilePhoto) {
                            f.append("set_as_photo", "true");

                            if(context.options.setAsProfilePhotoForUser && context.options.setAsProfilePhotoForUser.length > 0)
                            {
                                f.append("set_as_photo_for_user",context.options.setAsProfilePhotoForUser);
                            }
                        }

                        if(context.options.shareWithOrgId)
                        {
                            f.append("share_with_organization_id", context.options.shareWithOrgId);
                        }

                        if(context.options.attachToFormId) {
                            f.append("attached_to_form_id", context.options.attachToFormId);
                        }

                        if(context.options.additionalUploadParams && context.options.additionalUploadParams.length > 0){
                            _.each(context.options.additionalUploadParams, function(val){
                                var actualVal;
                                if(typeof(val[1]) === "function")
                                {
                                    actualVal = val[1]();
                                }
                                else
                                {
                                    actualVal = val[1];
                                }
                                f.append(val[0], actualVal);
                            });
                        }
                        xhr.send(f);
                    }
                    else {
                        xhr.send(file);
                    }
                    
                }(file, xhr, fileElement, progressBar, context));
            });
        },

        _addAttachmentUi: function(filename, fileData, xhr) {
            const context = this;

            let upload_files = context.options.fileListUi,
                customFileUi = context.options.customFileUi,
                $attachment;

            if (customFileUi) {
                let data = {
                    title: filename,
                    icon: getFileIconHtml({fileType: guessMimeType(filename)})
                };

                $attachment = renderTemplate("attachment_library_attachment_upload_template", data).data("fileData", fileData);
            } else {
                $attachment = $('<li class="fileElement fileUploading ' + context.options.fileElementClass + '"><div class="fileDescription">' + filename + '</div><div class="fileProgress"></div></li>')
                                .data("fileData", fileData)
                                .toggleClass("attach_video_element", isRenderableVideoType(fileData.type));
            }
            return $attachment.twistle_removable({
                            alwaysShow: true,
                            onRemove: function(evt, el) {
                                // hide the file list if it is going to be empty
                                if(context.options.fileListUi.find(".fileElement,.library_attachment").length === 0) {
                                    context.options.fileListUi.hide();
                                }
                                evt.data = fileData;
                                context._trigger("attach_canceled", evt);
                                if(xhr){
                                    xhr.abort();
                                }
                                // remove attachment from the list
                                $(evt.currentTarget).remove();
                            }

                        })
                        .appendTo(upload_files);

        },

        _handleUploadFailure:function(evt, errorData)
        {
            var context = this, content = "Unable to upload file to Twistle: " + (errorData.error || "") + " " +
                (errorData.text || "") + "\n Please Try Again.";
            raiseAlert(content);
            if(!context.options.isMultiple)
            {
                // single upload uses auto-reset
                context.reset();
            }
        },

        _showAttachmentSuccessUi: function(attachUi, fileData, fullSize) {
            var context = this;
            attachUi.addClass("fileUploadSuccess");
            attachUi.addClass("file-" + fileData.seqnum);

            if(fileData.type === "image" || fileData.hasthumb || fileData.hasThumbnail) {
                attachUi.addClass("fileUploadSuccessWithThumb");
                attachUi.toggleClass("fileUploadSuccessWithFullImage", !!fullSize);
                var size = fullSize ? "full" : "prf";
                var $img = $("<img>", {
                    src: attachThumbSrc(fileData, size),
                    class: "attach_thumb",
                    ["data-type"]: fileData.type,
                    ["data-seqnum"]: fileData.seqnum,
                    ["data-size"]: size
                })
                attachUi.append($img);
                postRenderImages(attachUi);
            }
            else if(fileData.type === "twistle/form-definition")
            {
                attachUi.addClass("file_upload_with_form_definition");
            }
            else if(fileData.type === "twistle/form-submission")
            {
                attachUi.addClass("file_upload_with_form_submission");
            }
            fileData.upload_success = true;
            attachUi.data("fileData", fileData);
            attachUi.click(fileData, function(evt) {
                App.trigger(App.Events.GET_ATTACHMENT, evt.data.seqnum);
            }).twistle_removable({
                alwaysShow: true,
                onRemove: function(evt, origEvt) {
                    // hide the file list if it is going to be empty
                    if(context.options.fileListUi.find(".fileElement,.library_attachment").length === 0) {
                        context.options.fileListUi.hide();
                    }
                    evt.data = fileData;
                    context._trigger("attach_removed", evt, origEvt);
                }

            });
            attachUi.find(".fileProgress").hide();
            attachUi.removeClass("fileUploading");
            
            if(context.options.picUpload) {
                attachUi.hide();                
            }
        },

        destroy: function() {
            $.Widget.prototype.destroy.apply(this, arguments);
            // default destroy
            // now do other stuff particular to this widget
        },

        _guessMimeType:function(fileName)
        {
            return guessMimeType(fileName);
        },

        _fileTypeIsAllowed: function(fileType){
            const self = this;
            if(!self.options.allowedTypes){
                return true;
            }
            return self.options.allowedTypes.split(",").filter(typeMask =>{
                const maskParts = typeMask.split("/");
                // either it's a full match, or wildcard on the first part
                return typeMask === fileType ||
                    (maskParts.at(1) === "*" && maskParts.at(0) === fileType.split("/").at(0))
            }).length > 0;
        }

    });
}($));
