import $ from 'jquery'
import { isHandheld } from 'util/breakpoints'
import { localize } from 'util/localize'
import 'jquery.growl'

export const GrowlStyle = {
    DEFAULT: "default",
    ERROR: "error",
    NOTICE: "notice",
    WARNING: "warning"
}

/**
 * Displays an ephemeral Growl alert in the error style.
 * @param  {string} message The error message that is displayed.
 * @returns The Growl object.
 */
export function showGrowlError(message) {        
    return showGrowl(message, localize("common-error", "Error"), true, false, GrowlStyle.ERROR);
}

/**
 * Displays an ephemeral Growl alert in the default style.
 * @param  {string} message The message that is displayed.
 * @param  {string} title The title of the alert that is displayed.
 * @returns The Growl object.
 */
export function showGrowlDefault(message, title) {
    return showGrowl(message, title);
}

/**
 * Displays a Growl alert.
 * @param  {string} message The body of the alert.
 * @param  {string} title="" The title of the alert.
 * @param  {boolean} automaticallyDismisses=true Whether the alert automatically disappears after 8 seconds.
 * @param  {boolean} showsProgressIndicator=false Whether the alert shows a progress indicator.
 * @param  {GrowlStyle} style=GrowlStyle.DEFAULT The style of the alert.
 */
export function showGrowl(message, title = "", automaticallyDismisses = true, showsProgressIndicator = false, style = GrowlStyle.DEFAULT) {
    const loader = growlProgressIndicator(showsProgressIndicator, style);
    const augmentedMsg = `
    <div class='tws-flex-row'>
        ${loader}
        <div class='tws-flex-cell'>${message}</div>
    </div>
    `;

    const location = isHandheld() ? "tc" : "br";

    return $.growl({
        title: title,
        message: augmentedMsg,
        style: style,
        location: location,
        fixed: !automaticallyDismisses,
        duration: 8000
    });
}

function growlProgressIndicator(showsIndicator, style) {
    if (!showsIndicator) {
        return "";
    }

    const styleModifier = style == GrowlStyle.DEFAULT ? "" : "tws-loader-light";

    return `<div class='tws-flex-cell-fixed mr-1'><div class='tws-small-loader ${styleModifier}'></div></div>`
}