/**
 * Generates the authentication headers for an XHR request.
 * @param {Object} appAccount - The App.account object.
 * @param {Object} appConfig - The App.config object.
 * @returns {Object} - A dictionary of the auth request headers.
 */
export function generateXHRAuthHeaders(appAccount, appConfig) {
    let requestHeaders = {};

    if (appAccount && typeof(appAccount.get("id")) !== "undefined") {
        requestHeaders["Twistle-Logged-In-User-Id"] = appAccount.get("id");
    }

    if (appConfig?.get("noCookies")) {
        const currentSessionId = appConfig?.getLocalSetting("twistle-session-id") || window["twistle-session-id"];
        if (currentSessionId?.length > 0 && currentSessionId !== "null") {
            requestHeaders["X-Twistle-SessionId"] = currentSessionId;
        }

        if (appConfig?.get("twistleApiKey")) {
            requestHeaders["X-Twistle-Native-API-Key"] = appConfig?.get("twistleApiKey");
        }
    }

    return requestHeaders;
}

/**
 * Stores the session ID from the response in the local settings, if it exists.
 * Only sets the token if there is no current session ID or if the request is a login or password reset request.
 *
 * @param {XMLHttpRequest} xhr - The XMLHttpRequest object.
 * @param {string} url - The URL of the request.
 * @param {object} appConfig - The App.config object.
 */
export function storeSessionIDFromResponse(xhr, url, appConfig) {
    const sessionID = xhr.getResponseHeader("X-Twistle-SessionId");
    if (!sessionID) {
        return;
    }

    const currentSessionID = getCurrentSessionID();

    // only set the token if we didn't have one. This prevents session re-initialization,
    // unless the request was a login request or a password reset request.
    if (!currentSessionID || urlIsLoginOrPasswordRelated(url)) {
        appConfig?.setLocalSetting("twistle-session-id", sessionID);
    }
}

function getCurrentSessionID() {
    let currentSession;
    try {
        currentSession = window.localStorage.getItem("twistle-session-id") || window["twistle-session-id"];
    } catch (e) {
        currentSession = window["twistle-session-id"];
    }
    return currentSession;
}

function urlIsLoginOrPasswordRelated(url) {
    return url.indexOf("account/Login") > -1
        || url.indexOf("account/ConfirmReset") > -1
        || url.indexOf("account/EnterExternalKioskMode") > -1
        || url.indexOf("account/ResetPassword") > -1
        || url.indexOf("account/ExternalFormAuth") > -1;
}