export function nullifyBlankObject(obj) {
    if (typeof obj === 'undefined') {
        return null;
    }
    else if (obj === null) {
        return null;
    }
    else if (typeof obj === 'string' && obj === '') {
        return null;
    }
    else {
        return obj;
    }
}